import '../assets/styles/formLogin.css';
import Eye from '../assets/images/view.png';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AuthService } from "../services/AuthService";
import { useFetch } from "../hooks/fetchHook";
import {useState} from "react";
import {toast} from "react-toastify";

export const FormLogin = () => {

    const history = useHistory();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [ fetchRequest,_,saveAnalytic ] = useFetch();
    const [loading, setLoading] = useState(false);
    const [viewPassword, setViewPassword] = useState('password');

    const onSubmit = (data: any) => {
      //return;
        setLoading(true);
        const dataSend = `username=${data.username}&password=${encodeURIComponent(data.password)}&grant_type=password`;
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${ btoa("SSO:LEXIS_SERVICE_SSO") }`
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        // @ts-ignore
        fetchRequest('/security/oauth/token', dataSend, headers ).subscribe(
            (response: any) => {
                setLoading(false);
                if (response?.access_token) {
                    
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    // @ts-ignore
                    saveAnalytic(response.access_token).subscribe((resp:any) => {
                        console.log("Save anl")
                    }, (error:any) => console.log(error));

                    sessionStorage.setItem('a', btoa(data.username));
                    sessionStorage.setItem('b', btoa(data.password));

                    // Almacenamos la sesión del usuario
                    localStorage.setItem('user', JSON.stringify(response));
                    AuthService.setLogin(response);
                    toast(response?.message || 'Bienvenido!!', {type: 'success'});
                } else {
                    toast(response?.message || 'Error en las credenciales de acceso.', {type: 'error'});
                }
            }
        )
      
    };

    const openExternalLink = () => {
        window.open('https://lexgo.lexis.com.ec/', '_blank');
    }

    const goRecoveryAccount = () => {
        history.push('/usuario/recuperar-cuenta');
    }

    const getError = (input: any) => {
        return input?.message || 'Por favor rellena este campo.';
    }

    const changePassword = () => {
        const newState = (viewPassword === 'password') ? 'text' : 'password';
        setViewPassword(newState);
    }

    return (
        <>
            <form className='form-login' onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: '2rem' }}>
                    <h1 className='primary-color' style={{margin: 0}}>Iniciar sesión</h1>
                    <p className='text-muted'>Ingresa tu dirección de correo electrónico y contraseña para acceder a Lexis.</p>
                </div>
                <div className='input-field-group'>
                    <label htmlFor="username">Usuario</label>
                    <input
                        type="email"
                        placeholder='Correo electrónico'
                        {...register('username', { required: true, min: 10 })}
                    />
                    {errors.username && <div className="invalid-feedback">{getError(errors.username)}</div>}
                </div>
                <div className='input-field-group container-password-input'>
                    <label htmlFor="password">Contraseña</label>
                    <input
                        type={viewPassword}
                        placeholder='Contraseña'
                        {...register('password', { required: true })}
                    />
                    <div className={'eye-content'} onClick={changePassword}>
                        <img src={Eye} alt="ver" height={20}/>
                    </div>
                    {errors.password && <div className="invalid-feedback">{getError(errors.password)}</div>}
                </div>
                <p className='text-right link cursor-pointer' onClick={goRecoveryAccount}>
                    ¿Olvidó su contraseña?
                </p>
                <div>
                    <button className='button' type='submit' disabled={loading}>
                        { loading && <div className="loader"/> } Iniciar sesión
                    </button>
                </div>
                <p className='text-center cursor-pointer text-muted' onClick={openExternalLink}>
                    ¿Primera vez en Lexis? <span className='link'>Compra en Línea</span>
                </p>
            </form>
        </>
    );
}
